













import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({
  components: {
    VueApexCharts
  }
})
export default class BarChart extends Vue {
    @Prop() series;
    @Prop() chart_title;
    @Prop() unit;

    @Global.State('lang') lang;

    chartOptions = {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: []
          },
          export: {
            csv: {
              show: false,
              filename: '',
              columnDelimiter: ',',
              headerCategory: 'Date',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toString();
              }
            },
            svg: {
              filename: ''
            },
            png: {
              filename: ''
            }
          },
          autoSelected: 'zoom' 
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      markers: {
        size: 0
      },
      title: {
        text: '',
        align: 'center',
        style: {
          fontFamily: 'Open Sans, Arial, monospace'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        }
      },
      yaxis: {
        labels: {
          offsetX: -5,
          formatter: (val) => parseInt(val, 10) === val ? val : val.toFixed(1)
        },
        title: {
            text: '',
            offsetX: -20,
            style: {
                fontSize: '16px',
                fontFamily: 'Open Sans, Arial, monospace'
            }
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      tooltip: {
        shared: false,
        x: {
          format: 'dd/MM/yy HH:mm'
        },
        y: {
          formatter: (val) => {
            return val.toFixed(0);
          },
          title: {
            formatter: (seriesName) => seriesName
          }
        }
      }
    };

    mounted() {
        this.chartOptions.title.text = this.$t(this.chart_title);
        this.chartOptions.chart.toolbar.export.csv.filename = this.$t(this.chart_title);
        this.chartOptions.chart.toolbar.export.svg.filename = this.$t(this.chart_title);
        this.chartOptions.chart.toolbar.export.png.filename = this.$t(this.chart_title);
        this.chartOptions.yaxis.title.text = this.unit || '';
        this.chartOptions.chart.toolbar.export.csv.headerCategory = this.$t('Date');
    }
}
